import {
  FC,
  Fragment,
  PropsWithChildren,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { CSSProperties } from 'styled-components';
import { withUIContext } from '@/context';
import { pushDataLayer, useTranslation, encodeTitle } from '@/helpers';

import Text from '../Text';
import Image from '../Image';
import Icon from '../Icon';
import CustomLink from '../CustomLink';
import PopupShare from '../PopupShare';
import PostStyle, { ListWrapper } from './style';

type PostProps = {
  id: number;
  title: string;
  text: string;
  date: string;
  username?: string;
  userpic?: string;
  isInFavourite: boolean;
  popupIsOpen: boolean;
  isActive: boolean;
  hasList?: boolean;
  fromWidget?: boolean;
  openPopup: (
    node: ReactElement,
    obj: {
      paStyle: boolean;
      hideBackdrop: boolean;
      position: number[];
      title: string;
    }
  ) => void;
  addToFavourite: () => void;
  showNotification: (obj: { type: string; title: string }) => void;
  deactivatePost: () => void;
};

const Post: FC<PostProps> = ({
  id,
  title,
  text,
  date,
  username = 'Yan from Owner.One',
  userpic,
  openPopup,
  isInFavourite,
  addToFavourite,
  showNotification,
  popupIsOpen,
  isActive,
  deactivatePost,
  hasList = false,
  fromWidget = false,
}) => {
  const t = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [canExpand, setCanExpand] = useState(true);
  const [isSharePopup, setIsSharePopup] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const expand = (e: SyntheticEvent) => {
    let event = isOpen ? 'yansnotes_post_collapse' : 'yansnotes_post_expand';
    if (fromWidget) {
      event = `widget_${event}`;
    }
    pushDataLayer({
      event,
      target: e.target,
      element: e.currentTarget,
    });
    if (isOpen) {
      deactivatePost();
    }
    setIsOpen((prev) => {
      if (!prev && wrapper.current) {
        setTimeout(() => {
          if (!wrapper.current || !wrapper.current.parentElement) return;
          const elRect = wrapper.current.getBoundingClientRect();
          const parentRect =
            wrapper.current.parentElement.getBoundingClientRect();
          const count =
            elRect.top - parentRect.top + elRect.height - parentRect.height;
          if (count > 0) {
            wrapper.current?.parentElement.scrollBy({
              top: count,
              left: 0,
              behavior: 'smooth',
            });
          }
        }, 250);
      }
      return !isOpen;
    });
  };

  const copyText = (e: SyntheticEvent) => {
    const html = `<b>${title}</b><br><p>${text}</p>`;
    const plain = `${title}\n${text}`;
    const clipboardItem = new ClipboardItem({
      'text/html': new Blob([html], { type: 'text/html' }),
      'text/plain': new Blob([plain], { type: 'text/plain' }),
    });
    navigator.clipboard.write([clipboardItem]);
    pushDataLayer({
      event: fromWidget
        ? 'widget_yansnotes_post_copy_text'
        : 'yansnotes_post_copy_text',
      target: e.target,
      element: e.currentTarget,
    });
    showNotification({
      type: 'success',
      title: t('textCopied'),
    });
    deactivatePost();
  };

  const share = (e: SyntheticEvent) => {
    const rect = e.currentTarget.getBoundingClientRect();
    pushDataLayer({
      event: fromWidget
        ? 'widget_yansnotes_share_click'
        : 'yansnotes_share_click',
      target: e.target,
      element: e.currentTarget,
    });
    openPopup(
      <PopupShare
        title={title}
        text={text}
        url={`${location.origin}/yansnotes/${encodeTitle(title)}`}
      />,
      {
        paStyle: true,
        hideBackdrop: true,
        position: [rect.x + rect.width / 2, rect.y],
        title: t('shareTo'),
      }
    );
    setIsSharePopup(true);
    deactivatePost();
  };

  const addToFavouriteHandler = (e: SyntheticEvent) => {
    addToFavourite();
    pushDataLayer({
      event: fromWidget
        ? 'widget_yansnotes_add_to_favourite'
        : 'yansnotes_add_to_favourite',
      target: e.target,
      element: e.currentTarget,
    });
    deactivatePost();
  };

  const getList = (matchFromRegex: RegExpExecArray[]) => {
    if (!Array.isArray(matchFromRegex)) return [];

    return matchFromRegex.flatMap((listArray) => {
      if (!Array.isArray(listArray)) return [];

      return listArray.map((list) => {
        const tag = list.slice(1, 3);
        const items = list
          .replace(/<\/?[ou]l>/g, '')
          .replace(/<li>/g, '')
          .split('</li>')
          .filter((item) => item.trim() !== '');
        const string = list;

        return { tag, items, string };
      });
    });
  };

  useEffect(() => {
    if (ref.current) {
      setCanExpand(ref.current.offsetHeight < ref.current.scrollHeight);
    }
  }, [ref]);

  useEffect(() => {
    if (!popupIsOpen) {
      setIsSharePopup(false);
    }
  }, [popupIsOpen]);

  useEffect(() => {
    if (content.current && ref.current) {
      content.current.style.setProperty(
        '--max-height',
        `${ref.current.scrollHeight + 700}px`
      );
    }
  }, [content, ref]);

  const MainContent = (() => {
    let textRem = text;
    let content = [];
    let caret = 0;

    const pushElement = (
      item: RegExpExecArray | null,
      element: JSX.Element,
      nameOfAnElement: string
    ) => {
      switch (nameOfAnElement) {
        case 'list':
          const formattedText = textRem
            .slice()
            .replace(/<[ou]l>(.*)<\/[ou]l>/, '');
          content.push(formattedText);
          content.push(element);
          textRem = '';
          break;
        default:
          if (!item) return;
          const idx = item.index;
          const len = item[0].length;
          content.push(text.slice(caret, idx));
          content.push(element);
          textRem = text.slice((caret = idx + len));
          break;
      }
    };

    const links = Array.from(text.matchAll(/<a.*href="(.*)">(.*)<\/a>/gm));

    links.forEach((item) => {
      const el = (
        <CustomLink target="_blank" className={`link`} url={item[1]}>
          {item[2]}
        </CustomLink>
      );
      pushElement(item, el, 'link');
    });

    const arrayOfLists = Array.from(text.matchAll(/<[ou]l>.*<\/[ou]l>/gm));

    getList(arrayOfLists).forEach((list) => {
      const Comp = list.tag as unknown as FC<PropsWithChildren>;
      const el = (
        <ListWrapper key={list.string}>
          <Comp>
            {list.items.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </Comp>
        </ListWrapper>
      );
      pushElement(null, el, 'list');
    });

    content.push(textRem);

    switch (hasList) {
      case true:
        return ({ isOpen }: { isOpen: boolean }) => (
          <div
            ref={ref}
            className={`post_text font-avenir-regular ${isOpen ? 'open' : ''}`}
          >
            {content.map((item, i) => (
              <Fragment key={i}>{item}</Fragment>
            ))}
          </div>
        );
      default:
        return ({ isOpen }: { isOpen: boolean }) => (
          <Text
            ref={ref}
            className={`post_text font-avenir-regular ${isOpen ? 'open' : ''}`}
          >
            {content.map((item, i) => (
              <Fragment key={i}>{item}</Fragment>
            ))}
          </Text>
        );
    }
  })();

  return (
    <PostStyle
      ref={wrapper}
      data-title={encodeTitle(title)}
      $isActive={isActive}
    >
      <Text className="post_date font-argent">{date}</Text>
      <Text className={'post_title font-argent'}>{title}</Text>
      <div className={'post_content'}>
        <Image
          className={'post_logo'}
          src={userpic ?? '/images/svg/post-logo.svg'}
        />
        <div
          ref={content}
          style={{ '--max-height': '1100px' } as CSSProperties}
          className={`post_content__text ${isOpen ? 'open' : ''}`}
        >
          <Text className={'post_username font-avenir-regular'}>
            {username}
          </Text>
          <MainContent isOpen={isOpen} />
        </div>
      </div>
      <div className={'post_footer'}>
        {canExpand ? (
          <div className={'post_expand_btn'} onClick={expand} data-expand>
            <Text
              text={isOpen ? 'collapse' : 'expand'}
              className={'post_expand_text font-avenir-regular'}
            />
            <Icon
              name={'arrow-down icon'}
              className={`post_icon_expand ${isOpen ? 'open' : ''}`}
            />
          </div>
        ) : (
          <div />
        )}
        <div className={'post_buttons'}>
          <Icon name={'copy'} onClick={copyText} />
          <Icon
            name={'share'}
            className={`share_button ${isSharePopup ? 'active' : ''}`}
            onClick={share}
          />
          <Icon
            name={`${isInFavourite ? 'bookmark_fill' : 'bookmark'}`}
            onClick={addToFavouriteHandler}
          />
        </div>
      </div>
    </PostStyle>
  );
};

export default withUIContext<PostProps>(Post, [
  'openPopup',
  'showNotification',
  'popupIsOpen',
]);
