import styled from 'styled-components';

const PostStyle = styled.div<{ $isActive: boolean }>`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border-radius: 12px;
  scroll-margin-top: 100px;
  border: ${(props) =>
    props.$isActive ? '1px solid var(--sky200);' : 'none;'};

  .link {
    color: var(--sky200);

    &:hover {
      opacity: 0.75;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .post_date {
    color: rgba(13, 23, 36, 0.4);
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    margin-bottom: 16px;
  }

  .post_title {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    border-bottom: 1px solid #e6e6ea;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .post_logo {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  .post_content {
    position: relative;
    display: flex;
    gap: 8px;
    flex: 1 1;
    border-bottom: 1px solid #e6e6ea;
    white-space: pre-wrap;
  }

  .post_content::before {
    content: '';
    position: absolute;
    height: calc(100% - 56px);
    width: 1px;
    background: #e6e6ea;
    left: 18px;
    top: 38px;
  }

  .post_content__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1;
    max-height: 140px;
    transition: max-height 1s;
  }

  .post_content__text.open {
    max-height: var(--max-height);
  }

  .post_username {
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: 150%;
  }

  .post_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    overflow: hidden;
    -webkit-line-clamp: 4;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin-bottom: 16px;
    transition: all 0.5s;
    color: var(--dark100);
  }

  .post_text.open {
    -webkit-line-clamp: 100;
  }

  .post_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    height: calc(23px + 16px);
  }

  .post_expand_btn {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;
  }

  .post_expand_text {
    font-size: 15px;
  }

  .post_buttons {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
  }

  .icon .post_expand_btn {
    transition: color 0.25s, opacity 0.25s;
  }

  .icon:hover,
  .post_expand_btn:hover {
    opacity: 0.75;
  }

  .icon:active,
  .post_expand_btn:active,
  .icon:active::before {
    opacity: 1;
    color: var(--sky200);
  }

  .post_icon_expand {
    transition: transform 0.25s;
  }

  .post_icon_expand.open {
    transform: rotate(-180deg);
  }

  .share_button.active::before {
    color: var(--sky200);
  }
`;
export default PostStyle;

export const ListWrapper = styled.div`
  ul,
  ol {
    padding-inline-start: var(--sp3x);
  }
`;
